html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}
body {
  line-height: 1;
}
ol,
ul {
  list-style: none;
}
blockquote,
q {
  quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}

:root {
  --color-base: #5b5c5f;
  --color-back: #fff;
  --color-brand: #027eb6;
  --color-selection: #fff;
  --color-selection-back: #27aadd;
  --color-link-underline: color(var(--color-base) l(+30%));

  --base-unit: 0.8em;
  --bu: var(--base-unit);
  --bu-one-half: calc(var(--base-unit) * 1.5);
  --bu-double: calc(var(--base-unit) * 2);

  --font-face: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
  --font-size: 18px;
  --line-width: 33em;
  --line-height: 1.6;
}

::selection {
  background: var(--color-selection-back);
  color: var(--color-selection);
}

*,
*:before,
*:after {
  box-sizing: border-box;
}

html,
body {
  height: 100%;
}

body {
  font-family: var(--font-face);
  color: var(--color-base);
  background-color: var(--color-back);
  font-size: var(--font-size);
  line-height: var(--line-height);
  padding: var(--bu-double) var(--bu-double);
  -webkit-font-smoothing: antialiased;
  display: grid;
}

a {
  color: inherit;
  text-decoration: underline dotted;
  border-bottom: 2px solid color(var(--color-base) tint(90%));
  transition: color 0.2s, border-color 0.2s;
}
a:hover {
  outline: none;
  color: color(var(--color-base) shade(40%));
  border-color: var(--color-brand);
}

a::selection,
a > *::selection {
  text-shadow: none;
}

p {
  margin-bottom: var(--bu);
}

strong {
  font-weight: bold;
}

h2 {
  font-weight: bold;
  margin-top: var(--bu-double);
  margin-bottom: var(--bu);
}

ul {
  padding-left: var(--bu-double);
}

li {
  margin-bottom: var(--bu);
  list-style-type: disc;
}

li a {
  border-bottom: 0;
  font-weight: bold;
  color: var(--color-brand);
  text-decoration: none;
}

.container {
  width: 100%;
  max-width: var(--line-width);
  margin: auto;
}
